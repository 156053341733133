import React, { Component } from "react";
import { Helmet } from "react-helmet";
import Layout from "../layout";
import config from "../../data/SiteConfig";
import { Link } from "gatsby";
import AboutData from "../../data/AdminAbout.json";

class Default404 extends Component {
  render() {
    return (
      <Layout>
        <div className="about-container">
          <Helmet title={`404| ${config.siteTitle}`} />
        </div>
        <div className="page">
          <section className="page-image-section">
            <div className="page-image-wrapper">
              <img className="page-image" alt="404" src={AboutData.image} />
              <h1 className="page-title" style={{ textAlign: "center" }}>
                404
              </h1>
            </div>
          </section>
          <p className="page-default">
            Aradığınız sayfaya ulaşamadık.
            <br />
            <Link to="/">Ana sayfaya dönmek için tıklayınız.</Link>
          </p>
        </div>
      </Layout>
    );
  }
}

export default Default404;
